import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Title, Box } from '../components/Core';
import { device } from '../utils';
import { Col, Container, Row } from 'react-bootstrap';
import GlobalContext from '../context/GlobalContext';
import FullScreenLoader from '../components/FullScreenLoader';
import creditCardIcon from '../assets/image/svg/youfibre-creditcard-round.svg';
import queryString from 'query-string';
import PaymentForm from '../components/Forms/PaymentForm';
import { navigate } from 'gatsby';
import API from '../api/api';
import Seo from '../components/Seo/Seo';

const BoxStyled = styled(Box)`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const BoxInner = styled(Box)`
  margin-top: -65px;
  min-height: 100vh;
`;

const BigTitle = styled(Title)`
   {
    font-size: 2.8em;
  }
`;

const FormStyled = styled.div`
  padding: 40px 30px;
  @media ${device.sm} {
    padding: 53px 58px 50px;
  }
  border-radius: 10px;
  width: 100%;
`;

const PaymentPage = (props) => {
  const gContext = useContext(GlobalContext);
  const [formData, setFormData] = useState(null);
  const query = queryString.parse(props.location.search);
  const registrationData = {
    SearchPremiseResults: {
      properties: {
        SalesStatus: 'DIRECT_DEBIT',
      },
    },
  };

  if (!query || !query.contactid) {
    if (typeof window !== `undefined`) {
      navigate('/');
    }
  }

  useEffect(() => {
    if (formData) {
      gContext.showFullScreenLoader();

      API.post('/BillingModule/v1.0/gocardless/bank-lookup', {
        accountNumber: formData.accountNumber,
        branchCode: formData.sortCode,
        countryCode: 'GB',
      })
        .then(() => {
          console.log('%c1. Bank details are valid!', 'color:limegreen');
          return API.post(`/BillingModule/v1.0/contact/${query.contactid}/payment-methods`, {
            identityName: 'GOCARDLESS',
            authorizedDirectDebit: true,
            bankDetails: {
              accountNumber: formData.accountNumber,
              branchCode: formData.sortCode,
            },
          });
        })
        .then(() => {
          console.log('%c2. Updated payment details!', 'color:limegreen');
          navigate('/success-payment', {
            state: { registrationData },
          });
        })
        .catch((error) => {
          gContext.hideFullScreenLoader();
          gContext.setAPIErrorMsg({
            title: 'Payment Details Error',
            message: error.response ? error.response?.data.message : error
          });
        });
    }
  }, [formData]);

  return (
    <div>
      <Seo page="payment" />
      <FullScreenLoader title="Processing payment information ..." />
      <BoxStyled bg="#f7f8fa">
        <BoxInner className="d-flex align-items-center">
          <Container>

              <Row className="justify-content-center">
                <Col lg="12">
                  <FormStyled>
                    <Row>
                      <Col sm={12} lg={11} className="mx-auto p-2 p-lg-5 mt-4 mt-lg-0">
                        <div className="mb-5 pb-2 text-center">
                          <BigTitle className="mt-3 mb-2">
                            Confirm your payment Information
                          </BigTitle>
                        </div>
                        <PaymentForm setFormData={setFormData} />
                      </Col>
                    </Row>
                  </FormStyled>
                </Col>
              </Row>

          </Container>
        </BoxInner>
      </BoxStyled>
    </div>
  );
};

export default PaymentPage;
