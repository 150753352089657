import React, {useContext, useRef, useState, useEffect} from "react";
import GlobalContext from "../../context/GlobalContext";
import "./index.scss"
import { Row, Col } from "react-bootstrap";
import Loader from "../../assets/image/gif/loader.gif"

const FullScreenLoader = ({title}) => {
    const gContext = useContext(GlobalContext);

    return (
        <div>
            <div className="fullScreenLoader" style={{
                opacity: (gContext.fullScreenLoader  ? '1' : '0'),
                visibility: (gContext.fullScreenLoader  ? 'visible' : 'hidden')
            }}>
                <Row>
                    <Col lg={12} className="text-center">
                        <label className="mb-5">{title}</label>
                    </Col>
                    <Col lg={12} className="text-center">
                        <img src={Loader} alt="Loader" className="img-fluid mx-auto loader" />
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default FullScreenLoader