import React, { useEffect, useState } from 'react';
import { Box, Button, Input, Select, Text, Title } from '../Core';
import { AgreementSwitch } from '../Core/Switch';
import { Col, Row, FormControl } from 'react-bootstrap';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import {isMobile} from 'react-device-detect';
import './index.scss'

const LegalSwitchCard = ({
  color = 'primary',
  className,
  iconName,
  title,
  text,
  children,
  ...rest
}) => (
  <Box
    bg="light"
    border="none"
    borderColor="border"
    borderRadius={10}
    className={`d-flex ${className}`}
    {...rest}
    css={`
      min-width: 100%;
      width: 100%;

      .legal-text {
        font-size: 1em;
        line-height: 1.5em;
      }
    `}
  >
    <div className="mx-auto">
      <Title variant="card" mb={2}>
        {title}
      </Title>
      <Text className="legal-text pt-3 pl-0 pr-0 pl-lg-5 pr-0 pr-md-5">{text}</Text>
      {children}
    </div>
  </Box>
);

function validateFeedback(feedback) {
  if (feedback) {
    return (
      <FormControl.Feedback type="invalid" className="pl-1">
        {feedback}
      </FormControl.Feedback>
    );
  } else {
    return <></>;
  }
}

const PaymentForm = (props) => {
  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{ accountNumber: '', sortCode: '' }}
      validationSchema={yup.object().shape({
        accountNumber: yup.string().required('Please enter your account number'),
        sortCode: yup.string().required('Please enter your sort code'),
        agreementSwitch: yup.boolean().oneOf([true], 'We need your agreement here.'),
      })}
      onSubmit={(values) => {
        props.setFormData(values);
      }}
    >
      {({ values, errors, handleChange, setFieldValue, setFormData }) => (
        <Form>
          {/* First Name | Last Name */}
          <Row>
            <Col sm={12} lg={6}>
              <Box mb={4} className="position-relative">
                <Input
                  type=""
                  name="accountNumber"
                  placeholder="Account number"
                  value={values.accountNumber}
                  onChange={handleChange}
                  className={errors.accountNumber ? 'is-invalid' : 'is-valid'}
                />
                {validateFeedback(errors.accountNumber)}
              </Box>
            </Col>
            <Col>
              <Box mb={4} className="position-relative">
                <Input
                  type="password"
                  name="sortCode"
                  placeholder="Sort code"
                  value={values.sortCode}
                  onChange={handleChange}
                  className={errors.sortCode ? 'is-invalid' : 'is-valid'}
                />
                {validateFeedback(errors.sortCode)}
              </Box>
            </Col>
          </Row>

          {/* Legal Information & Switch */}
          <Row>
            <Col sm={12} lg={12} className="mb-3 mt-3">
              <LegalSwitchCard
                title="We need your agreement here"
                text="I authorize YouFibre to set up a direct debit with Bank details entered above."
                color="secondary"
                className="p-4 text-center"
              >
                <AgreementSwitch
                  name="agreementSwitch"
                  value={values.agreementSwitch}
                  setFieldValue={setFieldValue}
                  className={['mt-4 mb-2', errors.agreementSwitch ? 'is-invalid' : 'is-valid']}
                />
                {validateFeedback(errors.agreementSwitch)}
              </LegalSwitchCard>
            </Col>
          </Row>

          {/* Submit button */}
          <Row className="text-center">
            <Col sm={12} lg={12} className="mb-3 text-center">
              <Button
                style={{
                  width: isMobile ? '90%' : '30%',
                  borderRadius: 50,
                  fontSize: '1em',
                  color: 'white',
                  padding: '15px 30px',
                  backgroundColor: '#07131E',
                  borderColor: '#07131E',
                }}
                type="submit"
                borderRadius={10}
                className="mx-auto paymentButton"
                disabled={!values.agreementSwitch}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default PaymentForm;
